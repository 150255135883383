.label-error {
  text-align: left;
}

.line {
  border-top: 1px solid #ccc;
  padding: 0 30px;
  margin: 10px 0px 15px 0;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .bg_box_login {
    border: "none";
    width: '100%';
    margin-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 601px) {
  .bg_box_login {
    text-align: "center";
    background-color: #fff;
    width: 600px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: auto;
    margin-bottom: 80px;
    border-radius: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -2px,
      rgba(0, 0, 0, 0.14) 0px 7px 10px 1px, rgba(0, 0, 0, 0.12) 0px 2px 16px 1px !important;
  }
}
