.btn-appbar {
  &:hover {
    color: #007f18;
  }
}
.wrapper {
  width: 100%;
  margin-top: -10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding-top: 8px;
  box-sizing: border-box;
  max-height: min(-156px + 100vh, 760px);

  list-style-type: none;
}
.input-container {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid #c4c4c4;
  width: 90%;

  border-radius: 20px;
  padding: 3px;
  .icon-search {
    cursor: pointer;
  }
  &:hover {
    .icon-search {
      font-weight: 600;
      color: #333;
    }
  }
  @media screen and (max-width: 600px) {
    .input-search {
      width: 85% !important;
      padding: 8px 8px 8px 6px;
      margin-left: 5px;
      font-size: 12px;
      border: none;
      outline: none;
    }
  }
  @media screen and (min-width: 601px) {
    .input-search {
      padding: 10px;
      margin-left: 5px;
      font-size: 16px;
      border: none;
      outline: none;
      width: 100px;
    }
  }
}
.btn_login_mobile {
  font-size: 16px !important;
  padding: 10px 30px !important;
  margin-left: 25%;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e8ea;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d6d6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bab7b7;
}
.menu-item {
  &:hover {
    background-color: #e8e8ea;
  }
}
