.title-about-us {
  @media screen and (max-width: 600px) {
    font-size: 15px !important;
  }
  font-weight: 600 !important;
  font-size: 22px !important;
  text-transform: uppercase;
}
.about-us-details {
  white-space: pre-line;
}

.map-location {
  margin-top: -50px;

  .location {
    width: 70px;
    height: 70px;
    position: relative;
    top: 380px;
    left: 50%;
  }
}
@media screen and (max-width: 600px) {
  .main-about-us {
    margin-top: -35px;

    .fs {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .location {
      top: 220px;
      width: 50px;
      left: 47%;
      height: 50px;
      margin-top: 30px;
    }
  }

  .title {
    font-size: 13px;
  }
  .map-location {
    margin-bottom: 20px;
    // margin-top: 0px;
    img {
      width: 360px;
      height: 411px;
      border: 1px solid #878787;
      border-radius: 20px;
      margin-top: -10px;
    }
  }
}
.product-aboutus-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  gap: 5px;
}
