.star-requied {
  color: red;
}
label {
  margin-bottom: 5px;
}
.input-register {
  border: none;
}

.bg_box_register{
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 600px) {
  .bg_box_register {
    border: "none";
    width: "100%";
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 601px) {
  .bg_box_register {
    background-color: #fff;
    width: 600px;
    border-radius: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -2px,
      rgba(0, 0, 0, 0.14) 0px 7px 10px 1px, rgba(0, 0, 0, 0.12) 0px 2px 16px 1px !important;
  }
}
