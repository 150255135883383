.main-products {
  padding: 0px 5px;
}

.image-product {
  img {
    border: 1px solid #ccc;
    border-radius: 7px;
  }
  .btn__left,
  .btn__right {
    @media screen and (max-width: 600px) {
      top: 30%;
    }
    position: absolute;
    z-index: 999;
    background-color: #fff;
    top: 45%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .btn__left {
    margin-left: 30px;
  }
  .btn__right {
    margin-right: 30px;
  }
}

.image-detail img {
  @media screen and (max-width: 600px) {
    width: 56px;
    height: 56px;
  }
  margin-right: 20px;
  width: 100px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.active-image-detail {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.info-product-detail {
  flex: 1;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    width: "100%";
    margin-left: 0;
    .info-price {
      margin-right: 25px;

      .name-detail {
        width: 60%;
      }
    }
    .res-title {
      font-size: 14px;
    }
  }
  flex: 1;
  margin-left: 30px;

  .name-detail {
    @media screen and (max-width: 600px) {
      font-size: 20px;
      text-transform: capitalize;
    }
    // text-transform: uppercase;
    font-size: 21px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.003em;
    text-align: left;
    color: #22292e;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-right: 30px;
  }
}
.price-detail {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #007f18;
}
.title-price {
  font-size: 18px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: #333;
}
.choose-size {
  margin: 5px 0 15px;
  border-top: 1px solid #c4c4c4;
  .title-choose-size {
    @media screen and (max-width: 600px) {
      font-size: 15px;
      font-weight: 600;
    }
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin: 15px 0;
  }

  .btn-size {
    @media screen and (max-width: 600px) {
      button {
        flex-wrap: wrap;
        width: 45px !important;
        height: 50px !important;
        font-size: 13px !important;
      }
    }
    button {
      background-color: #9acc99;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      text-align: center;
      width: 55px;
      height: 60px;
      border-radius: 8px;
      padding-right: 10px;
      gap: 5px;
      color: #333;
      margin-right: 20px;
      border: 1px solid #9acc99;
    }
  }
}

.dimension-detail {
  margin-bottom: 10px;
  padding-right: -50px;
  display: flex;

  .title {
    margin-right: 15px;
  }
}
.available-detail {
  display: flex;
  margin-bottom: 25px;
  .title {
    margin-right: 30px;
  }
  .quantity_available {
    .quanttity {
      font-weight: 400;
      font-style: italic;
      font-size: 18px;
      line-height: 21.56px;
      letter-spacing: -0.30000001192092896px;
      color: #007f18;
      margin-right: 7px;
    }
  }
}
.quantity-detail {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  border-top: 1px solid #c4c4c4;
  padding-top: 25px;
  margin-bottom: 30px;
  .descript {
    font-style: italic;
    font-size: 18px;
    font-weight: 200 !important;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #393939;
  }
  input {
    @media screen and (max-width: 600px) {
      width: 100px;
    }
    width: 133px;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #878787;
    margin-left: 35px;
    margin-right: 10px;
  }
  span {
    font-size: 18px;
  }
}
.title-quantity {
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.btn-add-to-card {
  @media screen and (max-width: 600px) {
    width: 88px;
    padding: 8px;
    font-size: 13px !important;
  }
  background-color: #e4e5e9;
  display: flex;
  width: 145px;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 24px;
  padding: 12px 12px;
  border-radius: 25px;
  gap: 10px;
  border: 1px solid #87878780;
  margin-top: 5px;
  &:hover {
    background-color: #dadada;
  }
  .cart {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.30000001192092896px;
    margin-left: -5px;
  }
}
@media screen and (max-width: 600px) {
  .title,
  .contact-detail span,
  .psc span,
  .content-detail span {
    font-size: 14px !important;
  }
  .title,
  .contact-detail span {
    font-weight: 600 !important;
  }
}
.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.30000001192092896px;
  color: #000;
}
.content-detail {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.03rem;
  color: #484747;
}
.total-contact {
  @media screen and (max-width: 600px) {
    justify-content: space-around;
  }
  display: flex;
  justify-content: space-between;
}
.contact-detail {
  display: flex;
  flex-direction: column;
  justify-content: left;
  span {
    line-height: 25px;
    font-size: 18px;
    font-weight: 500;
  }
}

.btn-service {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8px;
}

.ZoomProduct {
  border: none;
  margin-top: -100px;
  margin-bottom: 60px;
  .alice-carousel {
    margin-top: -50px !important;
    margin-bottom: -80px !important;
  }
  .alice-carousel__dots {
    margin-top: 10px;
  }
  .btn__left,
  .btn__right {
    position: absolute;
    z-index: 999;
    background-color: #fff;
    top: 45%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .btn__left {
    margin-left: 30px;
  }
  .btn__right {
    margin-right: 30px;
  }
}
.alice-carousel__dots {
  text-align: left;
  margin-bottom: -10px;
  img:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px;
  }
}
.image-carousel-main {
  cursor: pointer;
  @media screen and (max-width: 600px) {
    // border-radius: 50px;
  }
}
.carousel-product-detail {
  display: inline-block !important;
}
.line-item-detail {
  border-top: 1px solid #ccc;
  margin-top: 10px;
}

.img_choiced {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 5px;
}
