.item-noti {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 0px 20px;
  margin-left: -7px;

  &:hover {
    background-color: #f9f6f6;
    border-radius: 6px;
    cursor: pointer;
  }
}

.btn-remove-item {
  color: #333;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  line-height: 53px;
  text-align: center;
  position: absolute;
  right: 5px;
  &:hover {
    border: #eee3e3 1px solid;
    background-color: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}

.menu-noti {
  color: #333;
  height: 30px;
  padding: 2px 3px 1px;
  border-radius: 50%;
  &:hover {
    background-color: #e7dede;
    border-radius: 100%;
  }
}

.icon-delete {
  // color: rgba(0, 0, 0, 0.6);
  padding: 4px 7px 3px 7px;
  border-radius: 100%;
}
.icon-mark-read,
.icon-select {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-mark-read {
  padding: 5px 7px 3px 7px;
  border-radius: 100%;
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .mark-read {
      display: block;
    }
  }
}
.icon-mark-disable {
  padding: 5px 7px 3px 7px;
  border-radius: 100%;
  cursor: default;
}
.icon-select {
  border-radius: 100%;

  .select {
    margin-top: 43px;
  }
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .select {
      display: block;
    }
  }
}

.mark-read,
.select {
  background-color: #00000066;
  border-radius: 10px;
  padding: 5px 10px;
  position: absolute;
  display: none;
  margin-top: 35px;
  z-index: 999;
  color: #fff;
}

@media screen and (max-width: 600px) {
  #fs-noti {
    font-size: 12px;
  }
}
@media screen and (min-width: 601px) {
  #fs-noti {
    font-size: 16px;
  }
}
