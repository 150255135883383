.title-best-sallers {
  font-size: 25px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.0008em;
  color: #333;
}

.item-best-sallers {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  min-height: 250px;
  margin: 30px 10px;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .image-best-sallers {
    width: 100%; // Hình ảnh sẽ chiếm 100% chiều rộng của phần tử cha
    height: 250px; // Đảm bảo tỷ lệ chiều rộng/chiều cao được giữ nguyên
    display: block; // Loại bỏ khoảng trắng dưới ảnh nếu có
    object-fit: cover;
  }
}
.disabled {
  display: none;
}

.btn__left,
.btn__right {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.btn__left {
  top: 35px;
  left: -30px;

  &.disabled {
    left: 0;
    opacity: 0;
  }
}

.btn__right {
  top: 35px;
  right: -30px;

  &.disabled {
    right: 0;
    opacity: 0;
  }
}
