.delete_pro {
  &:hover {
    color: #fff;
    background-color: #dadada !important;
  }
}

.btn_order {
  cursor: pointer;
  &:hover {
    background-color: #dadada;
  }
}

.input_qty {
  max-width: 70px;
  height: 25px;
  border: 1px solid #c4c4c4;
  border-radius: 4;
  margin-right: 5;
}

#cell {
  padding: 0;
}

.number-input {
  // width: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  #cell {
    font-size: 12px;
  }
  .number-input {
    width: 40px;
  }
}

@media screen and (min-width: 601px) {
  #cell {
    font-size: 16px;
  }
  .number-input {
    width: 70px;
  }
  .bg_box {
    margin: auto;
    margin-bottom: 40px;
    margin-top: -5px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 4px 7px 7px 4px rgba(0, 0, 0, 0.1);
  }
}

.bg_btn {
  margin-top: 20px;
  button {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #fff;
    padding: 12px 18px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
}

.btn-cancel {
  background-color: #ccc;
  margin-right: 15px;
}
.btn-confirm {
  background-color: #007f18;
  margin-left: 15px;
}
