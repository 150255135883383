.heading-pots {
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }

  font-size: 25px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: center;
  color: #50555c;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-pots {
  @media screen and (max-width: 600px) {
    width: 155px;
    padding-top: 10px;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #fff;
  border-radius: 10px;
  gap: 5px;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }

  .image-pots {
    display: block;
    object-fit: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .info-name-pots {
    color: #484747;
    font-size: 16px;
    font-weight: 700;
    line-height: 15px;
    text-align: center;
    padding: 10px 10px;
  }
}
