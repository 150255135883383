.title-best-sallers {
  @media screen and (max-width: 600px) {
    font-size: 20px !important;
  }
  font-size: 25px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.0008em;
  color: #333;
  margin-top: 20px;
  margin-left: 10px;
}

.item-best-sallers {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c4c4c4;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 5px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  &:hover {
    cursor: pointer;
  }
  .image-best-sallers {
    @media screen and (max-width: 600px) {
      width: 190px !important;
      height: 190px !important;
    }
    width: 90%; // Hình ảnh sẽ chiếm 100% chiều rộng của phần tử cha
    height: 250px; // Đảm bảo tỷ lệ chiều rộng/chiều cao được giữ nguyên
    display: block; // Loại bỏ khoảng trắng dưới ảnh nếu có
    object-fit: cover;
  }

  .name-best-sallers {
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0.003em;
    text-align: center;
    padding: 7px 0 5px;
  }
}
.disabled {
  display: none;
}

.bg_item_sallers {
  width: 200px;
}

.img_item_sallers {
  width: "100%";
  height: 200px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.carousel-special {
  .alice-carousel__stage-item {
    // width: 270px;
    margin: -15px 0px;
    padding: 0 10px;
  }
}
