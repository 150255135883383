.item-order {
  &:hover {
    background: #fafafa;
    cursor: pointer;
  }
  Td {
    padding: 20px 0;
  }
}
.item-header {
  Th {
    text-transform: uppercase;
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  #cell {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #status {
    font-size: 12px;
  }
  #item-cell {
    width: 70px;
    padding: 0;
    border: none;
  }

  .number-input {
    width: 40px;
  }
  .table {
    margin: 10px 0 10px 10px;
  }
}

@media screen and (min-width: 601px) {
  #cell {
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  #status {
    font-size: 16px;
  }
  #item-cell {
    width: 200px;
    padding: 0;
    border: none;
  }

  .number-input {
    width: 70px;
  }
  .bg_order {
    margin: auto;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 4px 7px 7px 4px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .table {
    margin: 10px 0 10px 60px;
  }
}

.table-row-status {
  &:hover {
    background-color: transparent !important;
  }
}
