@media screen and (max-width: 600px) {
  .item {
    display: flex;
    flex-direction: column;
    align-items: none;
    font-size: 13px;
    border: none !important;
    border-bottom: 1px solid #c4c4c4 !important;
    padding: 10px 0;

    .size-products {
      min-height: auto !important;
      border-left: none !important;
    }
  }
  .pagination {
    width: 320px;
    font-size: 13px;
  }
}
@media screen and (min-width: 601px) {
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.info-products__heading {
  .heading {
    font-size: 25px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .title-heading {
    display: flex;
    background-color: rgb(154, 204, 153);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.0008em;
    text-align: right;
    border: 1px solid #c4c4c4;
    height: 47px;
    color: #50555c;
    .title-plant {
      width: 39%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .title-value-size {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #c4c4c4;
    }
  }
}
.container-size {
  background-color: #ffffff;
  margin-bottom: 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.item {
  border: 1px solid #c4c4c4;
  border-top: none;
  gap: 10px;
  &:hover {
    background-color: #f9f6f6;
  }
  .content-products {
    display: flex;
    align-items: center;
  }

  .size-products {
    padding-left: 20px;
    flex: 1;
    display: flex;
    border-left: 1px solid #c4c4c4;

    align-items: center;

    .size-detail {
      Button {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
      .product-name {
        line-height: 15px;
        letter-spacing: -0.0008em;
        text-align: right;
        span {
          text-transform: uppercase;
        }
      }
      .product-item-size .product-name {
        text-align: left;
        color: #333;
        font-weight: 500;
      }
      .product-item-size-max0 .product-name {
        color: #ccc;
      }
    }

    .list-sizes {
      display: flex;
      // justify-content: center;
      align-items: center;
      .size-quantity {
        color: #8a8a8e;

        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.0008em;
        text-align: right;
      }
    }
    .chevron-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .chevron-icon .icon {
      color: #333;
      margin-right: 14px;
      text-align: center;
      margin-bottom: 7px;
    }
  }
}

.pagination {
  display: flex;
  margin-bottom: 50px;
  margin-top: -15px;
  align-items: center;
  justify-content: right;
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #333;
  background-color: rgba(0, 0, 0, 0.3);
}

.pagination > li > a:hover {
  background-color: #cfc8c8;
  color: #333;
  cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 6px 12px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  color: #333;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  margin-left: 0;
  padding: 6px 12px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #333;
}
.disabled {
  display: none;
}
.data-empty {
  color: #333;
  text-align: center;
  margin: 20px 0;
}
