.carousel .thumbs,
.carousel .control-dots,
.carousel .carousel-status {
  display: none;
}

// description

.slide-homepage {
  margin-top: -25px;
}
.carousel .slide-content,
.carousel .slide-title {
  background: #00000066;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  padding-left: 30px;
  gap: 10px;
  text-align: left;
}
.carousel .slide-title {
  font-weight: 700;
  margin-bottom: 10px;
}

//products
.product-homepage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  margin-bottom: 30px;
  gap: 30px;
}

.product-homepage-mobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -10px;
  margin-bottom: 30px;
  gap: 5px;
}

.product-homepage .plant,
.product-homepage .plastic-pots {
  background-color: #ffffff;

  height: 200px;
  border-radius: 20px;
  border: 1px solid #878787;
  position: relative;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.plant {
  width: 50%;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
  &:active {
    animation-duration: 3s;
  }
}

.plant .plant-tree {
  position: absolute;
  top: -60px;
  left: 0px;
}
.plant .plant-logo-img,
.plastic-items .logo-pots {
  position: absolute;
  top: 10px;
  right: 10px;
}
.plant-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.003em;
  text-align: left;
  position: absolute;
  bottom: 20px;
  left: 38%;
}

.plastic-pots {
  flex: 1;
  &:hover {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
  &:active {
    transform: scale(0.999);
  }
  .pots {
    margin-left: 25px;
    margin-top: 17px;
  }
}

.plastic-title {
  position: absolute;
  bottom: 20px;
  left: 150px;

  font-size: 25px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.003em;
}
