// BestSallers

.see-best-sallers {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.0008em;
  margin-right: 20px;
  color: #007f18;
}
.line-border {
  height: 47px;
  border-left: 1px solid #c4c4c4;
}
.total-best-sallers {
  .name-sallers {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

// Infomation products

.info-products {
  flex: 1;
  margin-top: -10px;

  .heading_plants {
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: center;
    color: #50555c;
  }
}
.line-horizontal {
  border-top: 1px solid #ccc;
}
.list-size {
  margin: 20px 0 40px;
}
