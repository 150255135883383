.user-name {
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.18000000715255737px;
  margin-top: 10px;
}
.btn-save {
  border-radius: 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.Modal {
  border: none;
}
.modal-confirm-logout {
  button {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #fff;
    padding: 12px 18px;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
  .btn-cancel {
    background-color: #ccc;
    margin-right: 15px;
  }
  .btn-confirm {
    background-color: #007f18;
    margin-left: 15px;
  }
  .line-horizontal {
    border-top: 1px solid #ccc;
    padding: 0 20px;
    margin: 10px 0px 15px 0;
    width: 100%;
  }
}
.item-profile {
  Td {
    padding: 12px 0 10px;
  }
}
.order {
  &:hover {
    cursor: pointer;
  }
}
